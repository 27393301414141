import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import BannerImage from './img/moofasa_banner_edit1.png';
import {
  Typography,
  GridList,
  GridListTile,
  Button, Paper
} from "@material-ui/core";
import tileData from './homeImgGridData';
import Cards from './cards'
import {Link} from "react-router-dom";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  subheader: {
    width: '100%',
  },
});

class HomeView extends Component {
  render() {
    return (
      <div>
        <img src={BannerImage} style={{width: '100%'}}/>
        <div style={{minHeight: '30px'}}/>


        <Grid fluid>

          <Row start='xs'>
            <Col lg={5} lgOffset={2}>
              <Typography variant="display1" gutterBottom>
                Highlights
              </Typography>
              <GridList cellHeight={160} className={this.props.classes.gridList} cols={3}>
                {tileData.map(tile => (
                  <GridListTile key={tile.img} rows={tile.rows || 1}>
                    <img src={tile.img} alt={tile.title} />
                  </GridListTile>
                ))}
              </GridList>
            </Col>
            <Col lg={3}>
              <Typography variant="display1" gutterBottom>
                Our Goal
              </Typography>
              <Typography variant="title" gutterBottom>
                To provide wholesome, tasty food products for your table!<br/><br/>
                Everything we do is aimed at delivering the best quality food using sustainable farming methods.<br/><br/>
                We do not use anything to artificially promote animal growth or performance. They just eat as they were meant to eat.<br/><br/>
                In other words, they live on the land - naturally!
              </Typography>
              <div style={{minHeight: '20px'}}/>

              <Link to='/about' style={{ textDecoration: 'none' }}>
                <Button variant='contained' color='primary'>Learn More</Button>
              </Link>
            </Col>

          </Row>
          <div style={{minHeight: '50px'}}/>
          <Cards/>
        </Grid>
      </div>
    );
  };
}

export default withStyles(styles)(HomeView);