import image1 from './img/cf/web/cf-29.jpg';
import image2 from './img/cf/web/cf2-39.jpg';
import image3 from './img/cf/web/cf-52.jpg';
import image4 from './img/cf/web/cf2-20.jpg';
import image5 from './img/cf/web/cf-21.jpg';
import image6 from './img/cf/web/cf2-29.jpg';

const tileData = [
  {
    img: image1,
    title: 'Image',
    author: 'author',
    rows: 1,
  },
  {
    img: image2,
    title: 'Image',
    author: 'author',
    rows: 1,
  },
  {
    img: image3,
    title: 'Image',
    author: 'author',
    rows: 1,
  },
  {
    img: image4,
    title: 'Image',
    author: 'author',
    rows: 1,
  },
  {
    img: image5,
    title: 'Image',
    author: 'author',
    rows: 1,
  },
  {
    img: image6,
    title: 'Image',
    author: 'author',
    rows: 1,
  },
];

export default tileData;