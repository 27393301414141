import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import image1 from './img/cf/lg/cf-45.jpg';
import image2 from './img/cf/lg/cf2-19.jpg';
import image3 from './img/cf/lg/cf3-1.jpg';
import image4 from './img/cf/lg/cf-35.jpg';
import {
  Typography,
  Button
} from "@material-ui/core";
import Cards from './cards'

const styles = theme => ({
  img: {
    margin: theme.spacing.unit,
    width: '100%',
    border: '5px solid #ddd',
  }
});

class LandView extends Component {
  render() {
    return (
      <div>
        <div style={{minHeight: '30px'}}/>
        <Grid fluid>

          <Row start='xs'>
            <Col xs={10} xsOffset={2}>
              <Typography variant="display1" gutterBottom>
                The Land
              </Typography>
            </Col>
          </Row>
          <Row center='xs'>
            <Col md={3} >
              <img src={image1} className={this.props.classes.img}/>
            </Col>
            <Col md={3} >
              <img src={image2} className={this.props.classes.img}/>
            </Col>
            <Col md={3} >
              <img src={image3} className={this.props.classes.img}/>
            </Col>
          </Row>

          <Row center='xs'>
            <Col xs={9}>
              <Typography variant="Subheading" gutterBottom>
                Crestwood Family Farms operates on over 240 acres of rich, mid-western farmland.
                The property is a diverse mix of rich fields, forest, sedge meadow, creeks,
                and natural ground cover that provides both an ideal environment for raising
                farm animals as well as great habitat for wildlife.
              </Typography>
            </Col>
          </Row>

          <div style={{minHeight: '40px'}}/>
          <Row center='xs' middle='xs'>
            <Col lg={5}>
              <img src={image4} style={{width: '100%'}}/>
            </Col>
            <Col lg={3}>
              <Typography variant="Subheading" gutterBottom align='left' style={{marginLeft: 20}}>
                The wide variety of ecosystems make the land rich in nutrients.
                Also, the abundance of fresh ground water and natural springs promotes strong growth of vegetation and wildflowers.
                <br/>
                <br/>
                In addition to great farmland, the land is an excellent habitat for our bees
                so they produce plentiful amounts of fine raw honey!
              </Typography>

            </Col>

          </Row>

          <div style={{minHeight: '50px'}}/>

        </Grid>
      </div>


    );
  };
}

export default withStyles(styles)(LandView);
