import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import connect from "react-redux/es/connect/connect";

const styles = theme => ({
  img: {
    margin: theme.spacing.unit,
    width: '100%',
    border: '5px solid #ddd',
  },
  card: {
    width: 'auto',
    height: 'auto'
  },
  spacing: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: '#00a924',
    color: 'white'
  },
  formControl: {
    marginLeft: theme.spacing.unit,
  },
});

class Product extends Component {
  state = {
    selectedVariant: this.props.product.variants[0],
  };

  handleOptionChange = (event) => {
    const target = event.target;
    let selectedOptions = {};
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.client.product.helpers.variantForOptions(this.props.product, selectedOptions)

    this.setState({
      selectedVariant: selectedVariant,
    });
  };


  render() {
    console.log(this.state.selectedVariant);
    const product = this.props.product;
    return (
      <div>
        <div style={{minHeight: '15px'}}/>
        <Paper className={this.props.classes.spacing}>
          <Row>
            <Col md={12}>
              <Typography gutterBottom variant="title" className={this.props.classes.spacing}>
                {product.title}
              </Typography>
            </Col>
          </Row>

          <Row start='md' center='xs' middle='xs'>
            <Col md={7}>
              <img src={product.images[0].src} style={{width: '100%'}}/>
            </Col>


            <Col md={5}>
              <Typography variant="subheading" className={this.props.classes.spacing}>
                ${product.variants[0].price}
              </Typography>

              {product.variants.length > 1?
                <FormControl className={this.props.classes.formControl}>
                  <InputLabel htmlFor="name-disabled">{product.options[0].name}</InputLabel>
                  <Select
                    value={this.state.selectedVariant.title}
                    onChange={this.handleOptionChange}
                    name="Finish"
                  >
                    {product.variants.map((variant, i) => {
                      return (
                        <MenuItem disabled={variant.title.toUpperCase().includes('OUT')} value={variant.title}>{variant.title}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl> : null
              }
              <Button
                variant="contained"
                // color="green"
                className={this.props.classes.button}
                onClick={() => this.props.onAddToCartClick(this.state.selectedVariant.id)}
              >
                Add to Cart
              </Button>
            </Col>
          </Row>

          <Col xs='12'>
            <Typography component="p" className={this.props.classes.spacing}>
              {product.description}
            </Typography>
          </Col>
          <div style={{minHeight: '8px'}}/>

        </Paper>
      </div>
    )
  };
}

const mapStateToProps = (state, ownProps) => (
  {
    client: state.client,
    ...ownProps,
  }
);

export default Product = withStyles(styles)(connect(
  mapStateToProps,
  null)(Product));