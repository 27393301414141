import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import NavBar from './navBar'
import CondensedNavBar from './condensedNavBar';
import {
  Route,
  Switch,
  Redirect, BrowserRouter as Router
} from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Footer from './footer';

import HomeView from './home'
import GrassFedBeefView from './grassFedBeef'
import BeefSamplePacksView from './beefSamplePacks'
import LandView from './land'
import GalleryView from './gallery'
import AboutView from './about'
import WoodstockView from './woodstock'
import NoMatch from './noMatch'
import ComingSoonView from './comingSoon'
import { connect } from "react-redux";
import Cart from "./Cart";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#57718D',
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      isCartOpen: false,
      checkout: { lineItems: [] },
      products: [],
      shop: {}
    };
    this.props.initClient(this.props.client);

    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillMount() {
    this.props.client.checkout.create().then((res) => {
      console.log(res.id);
      this.props.updateCheckout(res)
    });

    this.props.client.product.fetchAll().then((res) => {
      while (res[0].images === undefined) continue;
      this.props.initProducts(res);
    });

    this.props.client.shop.fetchInfo().then((res) => {
        this.props.initShop(res)
    });
  }


  handleWindowResize = () => {
    console.log(window.innerWidth);
    this.setState({windowWidth: window.innerWidth});
  };

  handleAddToCartClick = (itemId) => {
    console.log('addToCart', itemId);
    this.props.openCart();
    const lineItemsToAdd = [{'variantId': itemId, quantity: 1}];
    const checkoutId = this.props.checkout.id;

    this.props.client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
      console.log('res', res.lineItems[0].id);
      this.props.updateCheckout(res);
    });
  };

  removeLineItemInCart = (lineItemId) => {
    const checkoutId = this.props.checkout.id;

    return this.props.client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
      this.props.updateCheckout(res);
    });
  };

  updateQuantityInCart = (lineItemId, quantity) => {
    const checkoutId = this.props.checkout.id;
    console.log(lineItemId);
    const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

    return this.props.client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
      console.log('update quanitity', res.lineItems[0].quantity);
      this.props.updateCheckout(res);
    });
  };

  render() {
    return (
      <div className="App" >
        <Router>
          <MuiThemeProvider theme={theme}>
            <div style={{background: theme.palette.background.default}}>
              {this.state.windowWidth > 980? <NavBar/> : <CondensedNavBar/>}
              <Switch>
                <Route exact path='/home' component={HomeView} />
                <Route exact path='/products/grassFedBeef' render={() => (
                  <GrassFedBeefView
                    onAddToCartClick={this.handleAddToCartClick}
                  />
                )}
                />
                <Route exact path='/products/beefSamplePacks' render={() => (
                  <BeefSamplePacksView
                    onAddToCartClick={this.handleAddToCartClick}
                  />
                )}
                />
                <Route exact path='/products/rawHoney' component={ComingSoonView} />
                <Route exact path='/land' component={LandView} />
                <Route exact path='/gallery' component={GalleryView} />
                <Route exact path='/blog' component={ComingSoonView} />
                <Route exact path='/about' component={AboutView} />
                <Route exact path='/woodstock' component={WoodstockView} />
                <Route exact path='/signUp' component={ComingSoonView} />
                <Route exact path='/' render={() => (
                  <Redirect
                    to='/home'
                  />
                )}
                />
                <Route exact path='/notFound' component={NoMatch} />
              </Switch>
              <div style={{minHeight: '100%'}}/>

              <Footer/>
            </div>
            <Cart
              updateQuantityInCart={this.updateQuantityInCart}
              removeLineItemInCart={this.removeLineItemInCart}
            />

          </MuiThemeProvider>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    checkout: state.checkout,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    initClient: (client) => {
      dispatch({
        type: 'INIT_CLIENT',
        client: client,
      });
    },
    initProducts: (products) => {
      dispatch({
        type: 'INIT_PRODUCTS',
        products: products,
      });
    },
    initShop: (shop) => {
      dispatch({
        type: 'INIT_SHOP',
        shop: shop,
      });
    },
    updateCheckout: (checkout) => {
      dispatch({
        type: 'UPDATE_CHECKOUT',
        checkout: checkout,
      });
    },
    openCart: () => {
      dispatch({
        type: 'OPEN_SHOPPING_CART',
      });
    },
  }
);

export default App = connect(
  mapStateToProps,
  mapDispatchToProps)(App);
