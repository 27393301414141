import {combineReducers} from "redux";

export const Reducer = combineReducers({
  client: clientReducer,
  isCartOpen: isCartOpenReducer,
  products: productsReducer,
  checkout: checkoutReducer,
  shop: shopReducer,
});

function clientReducer(state = {}, action) {
  switch (action.type) {
    case 'INIT_CLIENT': {
      return action.client;
    }
    default: {
      return state;
    }
  }
}

function isCartOpenReducer(state = false, action) {
  switch (action.type) {
    case 'ADD_TO_CART':
    case 'OPEN_SHOPPING_CART': {
      return true;
    }
    case 'CLOSE_SHOPPING_CART': {
      return false;
    }
    default: {
      return state;
    }
  }
}

function productsReducer(state = [], action) {
  switch (action.type) {
    case 'INIT_PRODUCTS': {
      return action.products;
    }
    default: {
      return state;
    }
  }
}

function checkoutReducer(state = { lineItems: [] }, action) {
  switch (action.type) {
    case 'UPDATE_CHECKOUT': {
      return action.checkout;
    }
    default: {
      return state;
    }
  }
}

function shopReducer(state = {}, action) {
  switch (action.type) {
    case 'INIT_SHOP': {
      return action.shop;
    }
    default: {
      return state;
    }
  }
}

