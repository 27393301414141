import image1 from './img/gallery/web/gallery-1.jpg';
import image2 from './img/gallery/web/gallery-2.jpg';
import image3 from './img/gallery/web/gallery-3.jpg';
import image4 from './img/gallery/web/gallery-4.jpg';
import image5 from './img/gallery/web/gallery-5.jpg';
import image6 from './img/gallery/web/gallery-6.jpg';
import image7 from './img/gallery/web/gallery-7.jpg';
import image8 from './img/gallery/web/gallery-8.jpg';
import image11 from './img/gallery/web/gallery-11.jpg';
import image12 from './img/gallery/web/gallery-12.jpg';
import image13 from './img/gallery/web/gallery-13.jpg';
import image14 from './img/gallery/web/gallery-14.jpg';
import image15 from './img/gallery/web/gallery-15.jpg';
import image19 from './img/gallery/web/gallery-19.jpg';
import image20 from './img/gallery/web/gallery-20.jpg';
import image22 from './img/gallery/web/gallery-22.jpg';
import image24 from './img/gallery/web/gallery-24.jpg';
import image25 from './img/gallery/web/gallery-25.jpg';
import image26 from './img/gallery/web/gallery-26.jpg';
import image27 from './img/gallery/web/gallery-27.jpg';
import image28 from './img/gallery/web/gallery-28.jpg';
import image29 from './img/gallery/web/gallery-29.jpg';
import image31 from './img/gallery/web/gallery-31.jpg';

const tileData = [
  {
    src: image1,
    caption: 'Cows Play, Bees Sleep'
  },
  {
    src: image2,
    caption: 'Cattle Feeding'
  },
  {
    src: image3,
    caption: 'Controlled Burns Help the Land'
  },
  {
    src: image4,
    caption: 'Gathering the Pollen'
  },
  {
    src: image5,
    caption: 'Berkshire Pigs'
  },
  {
    src: image6,
    caption: 'Hive Cluster'
  },
  {
    src: image7,
    caption: 'New Chicks!'
  },
  {
    src: image8,
    caption: 'Bringing in the Hay'
  },
  {
    src: image11,
    caption: 'Curious Chickens'
  },
  {
    src: image12,
    caption: 'Preparing the Garden'
  },
  {
    src: image13,
    caption: 'New Chicks!'
  },
  {
    src: image14,
    caption: 'Curious Chickens'
  },
  {
    src: image15,
    caption: 'Red Angus Cow'
  },
  {
    src: image19,
    caption: 'Cattle in a New Field\n'
  },
  {
    src: image20,
    caption: 'The Roo!'
  },
  {
    src: image22,
    caption: 'Chickens Love to Free-Range'
  },
  {
    src: image24,
    caption: 'Seasons Strengthen the Land'
  },
  {
    src: image25,
    caption: 'Controlled Burns Help the Forest Floors'
  },
  {
    src: image26,
    caption: 'Follow the Food'
  },
  {
    src: image27,
    caption: 'Winter Sunset'
  },
  {
    src: image28,
    caption: 'Hay Movements'
  },
  {
    src: image29,
    caption: 'Replenishing'
  },
  {
    src: image31,
    caption: 'Wild Turkeys'
  },
];

export default tileData;