import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import {
  Typography,
} from "@material-ui/core";

class ComingSoonView extends Component {
  render() {
    return (
      <div>
        <div style={{minHeight: '50px'}}/>
        <Grid fluid>

          <Row center='xs'>
            <Col md={6} >

              <Typography variant="display1" gutterBottom align='Center'>
                Coming Soon!
              </Typography>
            </Col>
          </Row>


        </Grid>
      </div>


    );
  };
}

export default (ComingSoonView);