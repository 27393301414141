import React from "react";
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Tabs,
  Tab,
  Typography,
  Toolbar,
  IconButton,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import logo from "./img/crestwoodLogo.PNG";
import connect from "react-redux/es/connect/connect";


const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  grow: {
    // flexGrow: 1,
    width: '100%',
  },
  appBar: {
    backgroundColor: '#57718D',
    height: 66,
  },
  button: {
    margin: '5px 5px 5px 5px',
    color: 'white',
  },
  shoppingCart: {
    margin: '5px 5px 5px 5px',
    color: 'white'
  }
});

class NavBar extends React.Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar >
            <img src={logo} style={{width: 170, height: 'auto'}}/>
            <span className={classes.grow}/>

            <Link to='/home' style={{ textDecoration: 'none' }}>
              <Button className={classes.button} style={{minWidth: 57}}>
                Home
              </Button>
            </Link>

            <Button
              buttonRef={node => {
                this.anchorEl = node;
              }}
              aria-owns={this.state.open ? 'menu-list-grow' : null}
              aria-haspopup="true"
              onClick={this.handleToggle}
              className={classes.button}
              style={{minWidth: 115, marginRight: 0}}
            >
              Products
              <ExpandMoreIcon/>
            </Button >

            <Popper open={this.state.open} anchorEl={this.anchorEl} transition disablePortal style={{marginTop: 5}}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        <Link to='/products/grassFedBeef' style={{ textDecoration: 'none' }}>
                          <MenuItem onClick={this.handleClose}>Grass-Fed Beef</MenuItem>
                        </Link>
                        <Link to='/products/beefSamplePacks' style={{ textDecoration: 'none' }}>
                          <MenuItem onClick={this.handleClose}>Beef Sample Packs</MenuItem>
                        </Link>
                        <Link to='/products/rawHoney' style={{ textDecoration: 'none' }}>
                          <MenuItem onClick={this.handleClose}>Raw Honey</MenuItem>
                        </Link>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Link to='/land' style={{ textDecoration: 'none' }}>
              <Button className={classes.button} style={{minWidth: 10}}><span style={{minWidth: 90}}>The Land</span></Button>
            </Link>
            <Link to='/gallery' style={{ textDecoration: 'none' }}>
              <Button className={classes.button} style={{minWidth: 80}}>Gallery</Button>
            </Link>
            <Link to='/blog' style={{ textDecoration: 'none' }}>
              <Button className={classes.button} style={{minWidth: 60}}>Blog</Button>
            </Link>
            <Link to='/about' style={{ textDecoration: 'none' }}>
              <Button className={classes.button} style={{minWidth: 65}}>About</Button>
            </Link>
            <Link to='/woodstock' style={{ textDecoration: 'none' }}>
              <Button className={classes.button} style={{minWidth: 110}}>Woodstock</Button>
            </Link>

            <IconButton
              className={classes.shoppingCart}
              onClick={this.props.handleCartOpen}
            >
              {this.props.checkout.lineItems.length ?
                <Badge badgeContent={this.props.checkout.lineItems.length} color="secondary" style={{zIndex: 0}}>
                  <ShoppingCart/>
                </Badge>:
                <ShoppingCart/>
              }
            </IconButton>

          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    checkout: state.checkout
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    handleCartOpen: () => {
      dispatch({
        type: 'OPEN_SHOPPING_CART',
      });
    },
  }
);

export default NavBar = withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps)(NavBar));


