import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import './App.css';
import {
  Button,
  GridList,
  GridListTile, MenuItem, MenuList,
  Typography,
} from '@material-ui/core';
import FacebookIcon from './img/find-us-on-facebook.svg';
import tileData from "./recentImgData";
import {Link} from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer className="footer" >
        <div style={{minHeight: '30px'}}/>
        <hr/>
        <div style={{minHeight: '20px'}}/>
        <Grid>

          <Row start='xs'>

            <Col mdOffset={1} md={3} >
              <Typography variant="title" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body1" gutterBottom>
                Crestwood Family Farms <br/>
                13118 IL Route 176 <br/>
                Woodstock, IL 60098 <br/>
                phone: 815-272-2606 <br/>
                fax: 312-896-5118 <br/>
                email: crestwoodfamilyfarms@gmail.com <br/>
              </Typography>

              <div style={{minHeight: '30px'}}/>

              <Typography variant="title" gutterBottom>
                Follow Us
              </Typography>
              <a href="https://www.facebook.com/crestwoodfamilyfarms">
                <img src={FacebookIcon} style={{width: '40%'}}/>
              </a>
            </Col>

            <Col md={4} >
              <Typography variant="title" gutterBottom>
                Stay Updated
              </Typography>
              <Typography variant="body1" gutterBottom>
                Sign up for our posts on new products and information. <br/>
                We won't share your email address.
              </Typography>

              <div style={{minHeight: '10px'}}/>
              <Link to='/signUp' style={{ textDecoration: 'none' }}>
                <Button variant='contained' color='primary'> Sign Up</Button>
              </Link>
              <div style={{minHeight: '50px'}}/>

              <Typography variant="title" gutterBottom>
                Recent Images
              </Typography>

              <GridList cellHeight={60} cols={6}>
                {tileData.map(tile => (
                  <GridListTile key={tile.img} rows={tile.rows || 1}>
                    <img src={tile.img} alt={tile.title} />
                  </GridListTile>
                ))}
              </GridList>

            </Col>

            <Col md={3} >
              <Typography variant="title" gutterBottom>
                Latest Blog Posts
              </Typography>
              <Typography variant="body1" gutterBottom>
                Feature coming soon!
              </Typography>
            </Col>

          </Row>

          <div style={{minHeight: '15px'}}/>

          <Row center='xs'>
            <Col xs={10} >
              <hr/>
            </Col>
            <Col
              xs={12}
            >
              <p style={{fontSize: 10}}>
                'PURE, SIMPLE GOODNESS.' AND 'CRESTWOOD FAMILY FARMS' ARE REGISTERED TRADEMARKS OF CRESTWOOD FARMS, LLC © ALL RIGHTS RESERVED - 2014-2022, CRESTWOOD FARMS, LLC
              </p>
            </Col>
          </Row>

        </Grid>
      </footer>
    )
  }
}

export default Footer;
