import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import image1 from './img/samples.jpg';
import {
  Paper,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button/Button";
import image2 from "./img/cf/lg/cf-30.jpg";
import image3 from "./img/cf/lg/cf-43.jpg";
import Product from './product'
import connect from "react-redux/es/connect/connect";

const styles = theme => ({
  img: {
    margin: theme.spacing.unit,
    width: '100%',
    border: '5px solid #ddd',
  },
  card: {
    width: 'auto',
    height: 'auto'
  },
  spacing: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    minWidth: 120,
  },
});



class BeefSamplePacksView extends Component {

  render() {
     return (
      <div>
        <div style={{minHeight: '30px'}}/>
        <Grid fluid>

          <Row >
            <Col md={6} mdOffset={1}>

              <Typography variant="display1" gutterBottom align='left'>
                Natural Beef Sample Packs
              </Typography>
              <Typography variant="title" gutterBottom align='left'>
                ** Order Now to Schedule Pickup **
              </Typography>

              <Typography variant="Subheading" gutterBottom align='left'>
                Crestwood Family Farms is pleased to begin offering sample packs of our all-natural, pasture-raised beef.
                <br/>
                <br/>
                Each pack contain our top-quality meat, which is raised naturally, is GMO-free,
                and contains no antibiotics or other artificial stimulants. All our animals live out in open pastures naturally. Nothing artificial. Just Pure, Simple Goodness ®
                <br/>
                <br/>
                The sample packs are large enough to include all portions and cuts,
                but small enough to fit in most freezers!
                They are a great way to plan your favorite cuts for a larger custom-processed order later!
                <br/>
                <br/>
                Order below.
              </Typography>

              <div style={{minHeight: '20px'}}/>

            </Col>
            <Col md={4} >
              <div >
                <img src={image1} className={this.props.classes.img}/>
              </div>
            </Col>
          </Row>

          <Row center='xs'>
            {this.props.products.map( product => {
              if (product.productType === 'Beef Sample') {
                return (
                  <Col md={5}>
                    <Product
                      product={product}
                      onAddToCartClick={this.props.onAddToCartClick}
                    />
                  </Col>
                )
              }
            })}
          </Row>
        </Grid>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => (
  {
    products: state.products,
    client: state.client,
    ...ownProps,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    handleAddToCartClick: (itemID) => {
      dispatch({
        type: 'ADD_TO_CART',
        itemID: itemID,
      });
    },
  }
);

export default BeefSamplePacksView = withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps)(BeefSamplePacksView));