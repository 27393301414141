import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import {
  Typography,
} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import galleryData from "./galleryData";

class GalleryView extends Component {
  render() {
    return (
      <div>
        <div style={{minHeight: '30px'}}/>
        <Grid fluid>

          <Row start='xs'>
            <Col xs={8} xsOffset={2}>
              <Typography variant="display1" gutterBottom>
                Photo Gallery
              </Typography>
            </Col>
          </Row>
          <Row center='xs'>
            <Col xs={8} >
              <Typography variant="body1" gutterBottom align='left'>
                Enjoy some great photos of life at Crestwood Family Farms. We believe in being good stewards of the land,
                animals, and habitat so that you get the highest quality food in a highly sustainable way!
              </Typography>
            </Col>
          </Row>
          <div style={{minHeight: '40px'}}/>
          <Row center='xs'>
            <Col  xs={8}>

              <Carousel >
                {galleryData.map(image => (
                  <div>
                    <img src={image.src} />
                    <p className="legend" style={{width: 'auto'}}>{image.caption}</p>
                  </div>
                  ))
                }
              </Carousel>

            </Col>
          </Row>
          <div style={{minHeight: '40px'}}/>
        </Grid>
      </div>
    );
  };
}

export default (GalleryView);