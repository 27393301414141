import React from "react";
import {
  Badge,
  AppBar,
  Toolbar,
  IconButton,
  Popper,
  Grow,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Menu,
} from '@material-ui/core'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import logo from "./img/crestwoodLogo.PNG";
import connect from "react-redux/es/connect/connect";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  grow: {
    // flexGrow: 1,
    width: '100%',
  },
  appBar: {
    backgroundColor: '#57718D',
    height: 66,
  },
  button: {
    margin: '0 5px 5px 5px',
    color: 'white',
  },
  shoppingCart: {
    margin: '5px 5px 5px 5px',
    color: 'white'
  }
});

class CondensedNavBar extends React.Component {
  state = {
    anchorEl: null,
    subMenuOpen: false,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSubMenuToggle = () => {
    this.setState(state => ({ subMenuOpen: !state.subMenuOpen }));
  };

  handleSubMenuClose = event => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar >
            <img src={logo} style={{width: 170, height: 'auto'}}/>
            <span className={classes.grow}/>

            <div>
              <IconButton
                aria-label="More"
                aria-owns={open ? 'long-menu' : null}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <MenuIcon style={{color: 'white'}} />
              </IconButton>
              <Menu
                buttonRef={node => {
                  this.anchorEl = node;
                }}
                aria-owns={this.state.open ? 'menu-list-grow' : null}
                aria-haspopup="true"

                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
                PaperProps={{
                  style: {
                    width: 200,
                  },
                }}
              >

                <Link to='/home' style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={this.handleClose}>
                    Home
                  </MenuItem>
                </Link>
                <MenuItem

                  onClick={this.handleSubMenuToggle}
                >
                  Products
                  <ExpandMoreIcon/>
                </MenuItem>
                <Popper open={this.state.subMenuOpen} anchorEl={this.anchorEl} transition disablePortal placement='left' style={{marginTop: 5}}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleSubMenuClose}>
                          <MenuList>
                            <Link to='/products/grassFedBeef' style={{ textDecoration: 'none' }}>
                              <MenuItem onClick={this.handleClose}>Grass-Fed Beef</MenuItem>
                            </Link>
                            <Link to='/products/beefSamplePacks' style={{ textDecoration: 'none' }}>
                              <MenuItem onClick={this.handleClose}>Beef Sample Packs</MenuItem>
                            </Link>
                            <Link to='/products/rawHoney' style={{ textDecoration: 'none' }}>
                              <MenuItem onClick={this.handleClose}>Raw Honey</MenuItem>
                            </Link>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Link to='/land' style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={this.handleClose}>
                    The Land
                  </MenuItem>
                </Link>
                <Link to='/gallery' style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={this.handleClose}>
                    Gallery
                  </MenuItem>
                </Link>
                <Link to='/blog' style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={this.handleClose}>
                    Blog
                  </MenuItem>
                </Link>
                <Link to='/about' style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={this.handleClose}>
                    About
                  </MenuItem>
                </Link>
                <Link to='/woodstock' style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={this.handleClose}>
                    Woodstock
                  </MenuItem>
                </Link>
              </Menu>
            </div>

            <IconButton
              className={classes.shoppingCart}
              onClick={this.props.handleCartOpen}
            >
              {this.props.checkout.lineItems.length ?
                <Badge badgeContent={this.props.checkout.lineItems.length} color="secondary" style={{zIndex: 0}}>
                  <ShoppingCart/>
                </Badge>:
                <ShoppingCart/>
              }
            </IconButton>

          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    checkout: state.checkout
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    handleCartOpen: () => {
      dispatch({
        type: 'OPEN_SHOPPING_CART',
      });
    },
  }
);

export default CondensedNavBar = withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps)(CondensedNavBar));
