import React from 'react';
import {
  Grid,
  Row,
  Col,
} from 'react-flexbox-grid';

const NoMatch  = () => (
  <div>
    <div style={{minHeight: '80px'}}/>
    <Grid>
      <Row>
        <Col
          xs={6}
          xsOffset={3}
          style={{textAlign:"center"}}
        >
          <h1>
            The page you're are looking
            for can't be found.
          </h1>
        </Col>
      </Row>
    </Grid>
    <div style={{minHeight: '230px'}}/>
  </div>
);

export default NoMatch;


