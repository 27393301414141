import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import {createStore} from "redux";
import { Reducer } from './rootReducer';
import Client from "shopify-buy";

const store = createStore(Reducer);

const client = Client.buildClient({
  storefrontAccessToken: 'b1b35ddb8268f69cf31be4d10ef401bc',
  domain: 'crestwoodfamilyfarms.myshopify.com'
});

ReactDOM.render(
  <Provider store={store}>
    <App client={client}/>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
