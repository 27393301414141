import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import image1 from './img/cattle_grazing.jpg';
import {
  Paper,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button/Button";
import image2 from "./img/cf/lg/cf-30.jpg";
import image3 from "./img/cf/lg/cf-43.jpg";
import Product from './product'
import connect from "react-redux/es/connect/connect";

const styles = theme => ({
  img: {
    margin: theme.spacing.unit,
    width: '100%',
    border: '5px solid #ddd',
  },
  card: {
    width: 'auto',
    height: 'auto'
  },
  spacing: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    minWidth: 120,
  },
});

const quarterBeefPerPound = '$6.85/lb hanging weight for a quarter beef, 100% grass-fed, or $7.81/lb with Organic grain finish.'
const quarterBeefApproxCost = '$1,253.94 ($1,405.19 with grain finish)'
const eighthBeefPerPound = '$7.56/lb hanging weight for an eighth beef, 100% grass-fed, or $8.50/lb with Organic grain finish.'
const eighthBeefApproxCost = '$690.00 ($765.00 with grain finish)'

class GrassFedBeefView extends Component {
  render() {
    return (
      <div>
        <div style={{minHeight: '30px'}}/>
        <Grid fluid>

          <Row start='xs'>
            <Col md={8} mdOffset={2}>

              <Typography variant="display1" gutterBottom align='left'>
                Grass-Fed Beef
              </Typography>
            </Col>
          </Row>
          <Row center='xs'>
            <Col sm={8}>
              <img src={image1} className={this.props.classes.img}/>
            </Col>
          </Row>

          <div style={{minHeight: '30px'}}/>
          <Row center='xs'>
            <Col sm={8}>
              <Typography component="h2" variant="headline" gutterBottom align='left'>
                Custom Quarters are available from our Fall 2022 Beefs.
              </Typography>

              <Typography variant="Subheading" gutterBottom align='left'>
                Farm-raised in open pastures. Our cattle live in open pastures where they eat pasture grasses
                and grass hay year round. We offer both 100% grass-fed beef and grass-fed beef that is finished
                with with Certified Organic (GMO-free) grains for protein. We don't use artificial growth stimulants,
                antibiotics, or hormones. Beef raised in open pasture means stronger, healthier animals as they freely
                roam, eat, and exercise making their meat both tasty and healthy.
                <br/><br/>
                Your order of an eighth or quarter beef will be fully butchered, packaged, and ready for eating and
                freezing. Each order of Quarter beef or larger includes an equal portion of all cuts: The various
                steaks, roasts, ground beef, ground round, and soup products.
                <br/><br/>
                Eighth-beef orders are not identically divisible, but will be split out as equivalently as possible.
                <br/><br/>
                <b>Actual beef price due at pickup is based on hanging weight as follows:</b>
                <ul>
                  <li>
                    <b>{quarterBeefPerPound}</b>A typical quarter beef hanging weight is 180 lbs and
                    results in about 110 lbs of final packaged beef.
                    <p> <b>>>  Approximate cost: {quarterBeefApproxCost}.</b> </p>.
                  </li>
                  <li>
                    <b>{eighthBeefPerPound}</b>A typical eighth beef hanging weight is 90 lbs and
                    results in about 55 lbs of final packaged beef.
                    <p> <b>>>  Approximate cost: {eighthBeefApproxCost}.</b></p>
                  </li>
                  <li>
                    A non-refundable deposit is required with your order. Payment may be made online by PayPal, credit card (via PayPal only), Cash App, or Bitcoin.
                  </li>
                  <li>
                    A pre-processing deposit will also be due prior to receiving your cutting instructions about 6 weeks before scheduled pickup.
                    <li>
                      The remaining balance is due at pickup.
                    </li>
                  </li>
                  <li>
                    Larger quantites available upon request. Please call, if interested.
                  </li>
                </ul>
              </Typography>

            </Col>
          </Row>

          <div style={{minHeight: '20px'}}/>


          <Row center='xs'>
            {this.props.products.map( product => {
              if (product.productType === 'Beef') {
                return (
                  <Col md={5}>
                    <Product
                      product={product}
                      onAddToCartClick={this.props.onAddToCartClick}
                    />
                  </Col>
                )
              }
            })}
          </Row>

          <div style={{minHeight: '30px'}}/>
          <Row center='xs'>
            <Col sm={8}>
              <Typography component="h2" variant="headline" gutterBottom align='left'>
                Beef Weights Explained (whole beef)
              </Typography>

              <Typography variant="Subheading" gutterBottom align='left'>

                <b>Actual beef price due at pickup is based on hanging weight as follows:</b>
                <ul>
                  <li>
                    Live weight (on the hoof) - The weight of the live, finished animal on arrival for processing.
                    Usually about 1200 lbs per whole beef.
                  </li>
                  <li>
                    Hanging weight - The weight of the beef carcass after initial cleaning and dressing, but before
                    butchering. Usually about 60% of the live weight, or around 720 lbs per whole beef.
                  </li>
                  <li>
                    Packaged weight - The weight of the meat final-cut, packaged and delivered. This is what you take
                    home. Usually about 40% of the live weight, or about 480 lbs per whole beef.
                  </li>
                </ul>
              </Typography>

            </Col>
          </Row>

        </Grid>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => (
  {
    products: state.products,
    client: state.client,
    ...ownProps,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    handleAddToCartClick: (itemID) => {
      dispatch({
        type: 'ADD_TO_CART',
        itemID: itemID,
      });
    },
  }
);

export default GrassFedBeefView = withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps)(GrassFedBeefView));
