import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {Paper, Toolbar, Typography} from '@material-ui/core';
import {
  Row,
  Col, Grid,
} from 'react-flexbox-grid';
import image1 from './img/cf/lg/cf2-54.jpg';
import image2 from './img/cf/lg/cf-30.jpg';
import image3 from './img/cf/lg/cf-43.jpg';
import {Link} from "react-router-dom";


const styles = theme => ({
  card: {
    width: 'auto',
    height: 'auto'
  },
  button: {
    margin: theme.spacing.unit,
  },
});

function Cards(props) {
  const { classes } = props;
  return (
    <div>
      <Row center='xs'>
        <Col xs={6} >
          <Typography variant="display1" gutterBottom>
            Interest Items
          </Typography>
        </Col>
      </Row>
      <Row center='xs'>
        <Col lg={3} >
          <Paper >
            <img src={image1} style={{width: '100%'}}/>
            <Typography gutterBottom variant="h5" component="h2">
              Why Natural?
            </Typography>
            <Typography component="p">
              Because it's better, of course.
            </Typography>
            <Link to='/about' style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary" className={classes.button}>
                Nature Info
              </Button>
            </Link>
          </Paper>
        </Col>
        <Col lg={3} >
          <Paper>
            <img src={image2} style={{width: '100%'}}/>
            <Typography gutterBottom variant="h5" component="h2">
              Why The Midwest?
            </Typography>
            <Typography component="p">
              Because our seasonal climate produces diverse ecosystems, soils rich in nutrients,
              and plentiful natural ground water. Together, these promote strong, healthy plants and animals!
            </Typography>
            <Link to='/about' style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary" className={classes.button}>
                Local
              </Button>
            </Link>
          </Paper>
        </Col>
        <Col lg={3}>
          <Paper>
            <img src={image3} style={{width: '100%'}}/>
            <Typography gutterBottom variant="h5" component="h2">
              Why Us?
            </Typography>
            <Typography component="p">
              Because it's just great food!
            </Typography>
            <Link to='/about' style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary" className={classes.button}>
                Who Are We
              </Button>
            </Link>
          </Paper>
        </Col>
      </Row>

    </div>
  );
}

Cards.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Cards);

