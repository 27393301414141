import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import logo from './img/about/bee_psg_graphic.png';
import image2 from './img/cf/lg/cf2-44.jpg';
import image3 from './img/cf/web/cf2-12.jpg';
import {
  Typography,
  Button
} from "@material-ui/core";
import Cards from './cards'

const styles = theme => ({
  img: {
    margin: theme.spacing.unit,
    width: '100%',
    border: '5px solid #ddd',
  }
});

class LandView extends Component {
  render() {
    return (
      <div>
        <div style={{minHeight: '30px'}}/>
        <Grid fluid>

          <Row start='xs'>
            <Col xs={8} xsOffset={2}>
              <Typography variant="display1" gutterBottom>
                About Us
              </Typography>
            </Col>
          </Row>
          <Row center='xs'>
            <Col md={5} >
              <Typography variant="Subheading" gutterBottom align='left'>
                Crestwood Family Farms is located in Woodstock, IL, a farming community in the heart of the midwest.
                <br/>
                <br/>
                Our guiding principle is that food should be good. Good for you and good for the land. Also, it is grown and raised in a natural way. We apply this standard to our local suppliers and partner farms as well, who are also family farms.
                <br/>
                <br/>
                Our food is raised using sustainable farming methods. In fact, all our activities are based on this goal, so we carefully integrate natural habitat into the farming environment. We believe it is important to not deplete nor harm the land while farming it.
                <br/>
                <br/>
                This means that while profitable use of the land is important (after all, it's not very sustainable otherwise), we are concerned about long-term habitat and wildlife just as much as near-term yield and agricultural productivity.
                <br/>
                <br/>
                A balanced approach to wildlife, habitat, and agriculture - that's sustainability in action...that's,

              </Typography>
              <div style={{minHeight: '20px'}}/>
              <img src={logo} style={{width: '60%', paddingLeft: 0, marginLeft: 0}}/>
              <div style={{minHeight: '20px'}}/>

              <Typography variant="Subheading" gutterBottom align='left'>
                Feel free to contact us! We would love to hear from you.
                <br/>
                <br/>
                <b>Phone: </b>815-272-2606
                <br/>
                <br/>
                <b>Email: </b>crestwoodfamilyfarms@gmail.com
              </Typography>

            </Col>
            <Col md={3} >
              <div style={{width: '72%'}}>
                <img src={image2} className={this.props.classes.img}/>
                <Typography variant="Subheading" gutterBottom align='left' style={{marginLeft: 10}}>
                  Bees provide great honey and a valuable service to vegetation and the land.
                  Rights reserved by Dust and Rainbows Photography
                </Typography>
              </div>

              <div style={{width: '72%'}}>
                <img src={image3} className={this.props.classes.img}/>
                <Typography variant="Subheading" gutterBottom align='left' style={{marginLeft: 10}}>
                  Diverse ecosystems work together and promote a healthy environment.
                  Rights reserved by Dust and Rainbows Photography
                </Typography>
              </div>
            </Col>
          </Row>


        </Grid>
      </div>


    );
  };
}

export default withStyles(styles)(LandView);
