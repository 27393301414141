import React, {Component} from 'react';
import {
  Col,
  Grid,
  Row,
} from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import image1 from './img/woodstock/woodstock_sm.jpg';
import {
  Typography,
} from "@material-ui/core";

const styles = theme => ({
  img: {
    margin: theme.spacing.unit,
    width: '100%',
    border: '5px solid #ddd',
  }
});

class LandView extends Component {
  render() {
    return (
      <div>
        <div style={{minHeight: '30px'}}/>
        <Grid fluid>

          <Row >
            <Col md={5} mdOffset={2}>

              <Typography variant="display1" gutterBottom align='left'>
                About Woodstock
              </Typography>

              <Typography variant="Subheading" gutterBottom align='left'>
                Woodstock, IL is a true Midwestern city where community and quality of life are values
                that are revealed in every street and sidewalk. Beginning in the center of its
                historic Square and moving out to its farm-cushioned edge, Woodstock is unique--a place that its
                citizens are proud to call home and visitors fall in love with. So whether you come
                because of the culture, entertainment, countryside, business opportunities, or just to be a
                part of its Victorian charm, Woodstock offers a million reasons to stay.
              </Typography>

              <div style={{minHeight: '20px'}}/>

              <Typography variant="Subheading" gutterBottom align='left'>
                <a href="https://www.woodstockil.gov/">More about Woodstock, IL</a>
              </Typography>

            </Col>
            <Col md={3} >
              <div >
                <img src={image1} className={this.props.classes.img}/>
              </div>
            </Col>
          </Row>


        </Grid>
      </div>


    );
  };
}

export default withStyles(styles)(LandView);